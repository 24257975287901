import {booleanAttribute, ChangeDetectionStrategy, Component, input} from '@angular/core';
import {NgClass, NgTemplateOutlet} from '@angular/common';

@Component({
  selector: 'app-icon-button',
  imports: [
    NgTemplateOutlet,
    NgClass
  ],
  templateUrl: './icon-button.component.html',
  styleUrl: './icon-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconButtonComponent {
  ariaLabel = input.required();
  disabled = input<boolean>();
  fullWidth = input(false, {transform: booleanAttribute});
}
