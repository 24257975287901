app-modal {
  width: 100%;
}

.modal-backdrop {
  background: rgba(127, 108, 99, 0.40);
}

.tooltip-backdrop {
  background: transparent;
}

.top-sticky {
  background: var(--white);
  padding: 1.6rem;
}

.cdk-overlay-container {
  z-index: 10000;
}

// menu animation
@keyframes show {
  0% {
    opacity: 0;
    transform: translateY(2rem); /* Move down slightly */
  }
  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(2rem); /* Move down slightly */
  }
}

app-modal {
  --duration: .2s;
  animation-duration: var(--duration);
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  position: relative;

  &.show {
    animation-name: show;
  }

  &.hide {
    animation-name: hide;
  }
}
